:root {
  --bs-primary: #E8674A; /* tuo colore primario */
  --bs-secondary: #D98570; /* derivato dal primario */
  --bs-success: #28a745; /* verde per successo */
  --bs-info: #17a2b8; /* blu per info */
  --bs-warning: #ffc107; /* giallo per attenzione */
  --bs-danger: #dc3545; /* rosso per pericolo */
  --bs-light: #f8f9fa; /* grigio chiaro */
  --bs-dark: #343a40; /* grigio scuro */
  --bs-muted: #6c757d; /* grigio per testi secondari */

    --en-dark-color: #343a40;
    --en-light-color: #f8f9fa;
    --en-divider-color: #17a2b8;

  --en-navbar-height: 60px;
    --en-header-height: 60px;
    --en-footer-height: 60px;
  --en-sidebar-width: 20em;
  --en-padding: 10px;
    --en-button-width:280px;
}

body, html {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
}
.header, .footer {
    width: 100%;
    height: var(--en-navbar-height);
    position: fixed;
    left: 0;
    z-index: 1;
}
.header {
    top: 0;
}
.footer {
    background-color: #F8F8F8;
    border-top: 1px solid #E7E7E7;
    position: fixed;
    left: 0;
    bottom: 0;
}

.fixed-bottom .btn {
    width: var(--en-button-width);
}

.content {
    margin-top: var(--en-navbar-height); /* Altezza dell'header */
    margin-bottom: var(--en-navbar-height); /* Altezza del footer */
    height: calc(100vh - calc(var(--en-navbar-height) * 2)); /* Altezza totale meno header e footer */
    overflow: auto;
    padding: var(--en-padding);
    transition: margin-left 0.3s ease;
    z-index: 1;
}

.content-navbar {
    margin-top: var(--en-navbar-height); /* Altezza dell'header */
    margin-bottom: var(--en-navbar-height); /* Altezza del footer */
    margin-left: var(--en-sidebar-width);
    height: calc(100vh - calc(var(--en-navbar-height) * 2)); /* Altezza totale meno header e footer */
    padding: var(--en-padding);
    overflow: auto;
    transition: margin-left 0.3s ease;
    z-index: 1;
}

.no-select {
    -webkit-user-select: none; /* Safari */
    -moz-user-select: none;    /* Firefox */
    -ms-user-select: none;     /* IE/Edge */
    user-select: none;         /* Standard syntax */
    width: 99%;
}

.fixed-top, .fixed-bottom {
    background-color: #2c3e50; /* Il colore blu scuro dell'immagine */
    color: white; /* Per il testo bianco */
    /* padding: 10px; */
    border-bottom: 1px solid #ddd;
}
.fixed-top {
    height: 60px;
}
.scrollable-content {
    overflow-y: auto;
    overflow-x: hidden;
    padding: 0 10px;
    margin: 140px 10px var(--en-footer-height);
}
.list-header {
    background-color: white; /* Imposta lo sfondo bianco per l'header della lista */
    position: sticky;
    top: 0;
    z-index: 10;
    padding: 10px;
    border-bottom: 1px solid #e7e7e7;
}
.list-header strong {
    font-weight: 500; /* Meno bold rispetto al default di Bootstrap */
    color: #6c757d; /* Grigio per i titoli */
}
.list-group-item-no-hover {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 10px 15px; /* Spazio maggiore per separare gli elementi */
    font-size: 1.0em; /* Leggermente più grande del default */
    border-bottom: none; /* Rimuove le righe tra gli item */
}

.list-group-item-no-hover-icon:hover {
    cursor: pointer;
}

.list-group-item {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 10px 15px; /* Spazio maggiore per separare gli elementi */
    font-size: 1.0em; /* Leggermente più grande del default */
    border-bottom: none; /* Rimuove le righe tra gli item */
}
.list-group-item:hover {
    background-color: #b2b6b9; /* Darker color on hover */
    color: whitesmoke;
    cursor: pointer;
}
.list-group-item-click {
    background-color: #2980b9; /* Darker color on hover */
    color: whitesmoke;
    cursor: pointer;
}
.list-group-item .col-7 {
    display: flex;
    align-items: center; /* Assicura che i contenuti siano allineati al centro verticalmente */
}
.list-group-item .fa-file-pdf {
    font-size: 1.5em; /* Aumenta la dimensione dell'icona */
    color: #2c3e50;
    margin-right: 10px;
}
.footer-content {
    display: flex;
    justify-content: end;
    padding: 10px;
    margin: 0 20px;
    background-color: white; /* Colore dello sfondo bianco per il footer */
    border-top: 1px solid #e7e7e7; /* Linea divisoria grigio chiaro */
}
.btn-primary {
    background-color: #2c3e50; /* Color for the Confirm button */
    border-color: #2c3e50; /* To remove any default border */
}
.btn-primary:hover {
    background-color: #2980b9; /* Darker color on hover */
    border-color: #2980b9;
}

.user-banner {
    background-color: #e0e0e0; /* Colore di sfondo grigio chiaro */
    padding: 1rem; /* Spaziatura interna */
    display: flex; /* Uso di flexbox per l'allineamento degli elementi */
    align-items: center; /* Allineamento verticale degli elementi */
    justify-content: space-between; /* Distribuzione dello spazio tra gli elementi */
    height: 80px;
}
.user-info {
    display: flex;
    align-items: center;
}
.user-info img {
    border-radius: 50%; /* Rende l'immagine del profilo circolare */
    margin-right: 1rem; /* Distanza tra l'immagine e il testo */
}
.user-details {
    display: flex;
    flex-direction: column; /* Imposta la direzione del layout a colonna */
}
.user-action {
    display: flex;
    align-items: center;
}
.user-action img {
    margin-left: 0.5rem; /* Distanza tra l'icona e il testo */
}

.fixed-top-without-container-margin {
    position: fixed;
    top: 60px;
    right: 0;
    left: 0;
    z-index: 1030;
}

#overlay {
    position: fixed;
    /* display: none; Inizialmente nascosto */
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0,0,0,0.5); /* Trasparenza nera */
    z-index: 10000; /* Si assicura che l'overlay sia sopra gli altri elementi */
    cursor: pointer;
}
#overlay-content {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    text-align: center;
}
.loader {
    border: 16px solid #f3f3f3; /* Light grey */
    border-top: 16px solid #3498db; /* Blue */
    border-radius: 50%;
    width: 120px;
    height: 120px;
    animation: spin 2s linear infinite;
}

.btn-primary {
    background-color: #2c3e50; /* Color for the Confirm button */
    border-color: #2c3e50; /* To remove any default border */
}
.btn-primary:hover {
    background-color: #2980b9; /* Darker color on hover */
    border-color: #2980b9;
}

.center-message{
    margin: 15% 0% 0% 43%;
}

/*input:invalid{
    border-color:var(--bs-danger);
}*/

@keyframes spin {
    0% { transform: rotate(0deg); }
    100% { transform: rotate(360deg); }
}

@media (max-width: 768px) {
    .content {
        margin-left: 0rem;
    }
}