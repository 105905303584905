.WDHeader {
    background-color: var(--en-dark-color);
    color: var(--en-light-color);
    border-bottom: 1px solid var(--en-divider-color);
    padding: 0;
    margin: 0;
    height: var(--en-header-height);
}

.WDHeader .logo {
    height: 50px; /* Adatta l'altezza come necessario */
    width: 50px; /* Adatta la larghezza come necessario */
    background-image: url('/public/Logo_wDesk.png'); /* Percorso all'immagine del logo */
    background-size: cover; /* Garantisce che il logo copra completamente l'area senza deformarsi */
    background-position: center; /* Centra l'immagine del logo */
    align-self: center; /* Allinea verticalmente il logo al centro nel flex container */
}

.logo:hover{
    cursor: pointer;
}

.fa-sign-out-alt:hover{
    cursor: pointer;
}