input{
    scroll-margin-top: 120px;
}

.max-height-80vh {
    max-height: 80vh;
    overflow-y: auto; /* Aggiungi lo scorrimento se il contenuto è più lungo */
}

.afterIdocsBarResult{
    margin-top: 3%;
}

.pdfViewer{
    height: calc(97vh - calc(var(--en-navbar-height)* 2));
}
