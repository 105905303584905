.max-height-80vh {
    max-height: 80vh;
    overflow-y: auto; /* Aggiungi lo scorrimento se il contenuto è più lungo */
}

.afterIdocsBarResult{
    margin-top: 3%;
}

.pdfViewer{
    height: calc(97vh - calc(var(--en-navbar-height)* 2));
}
@media (max-width: 768px) {
    .width-label-control {
        top: -12px !important;
        white-space: normal !important;
        height: 125% !important;
    }
}